import "../scss/main.scss";
import 'slick-carousel';
import LazyLoad from "vanilla-lazyload";

$(function () {

    $(document).on('gform_post_render', function () {
        $(".gfield.readonly input").attr("readonly", "readonly");
    });

    var lazyLoad = new LazyLoad();

    // Detect si la barre d'admin est présente
    var adminBar;
    if ($('#wpadminbar')) { adminBar = $('#wpadminbar').innerHeight() } else { adminBar = 0; }

    // BT retour vers le haut
    $('#up').on('click', function () {
        $('html, body').animate({ scrollTop: 0 }, "slow");
        return false;
    });

    // BT menu
    $('#btNav').on('click', function () {
        $(this).toggleClass('open');
        $('#nav-main').toggleClass('open');
        $('body').toggleClass('openMenu');

    });

    $('#nav-main > li.menu-item-has-children').each(function () {
        var $dropdownMenu = $(this).find('ul.sub-menu');
        $dropdownMenu.slideUp();
        $(this).removeClass('open');

        $(this).on('click', function () {
            if ($dropdownMenu.is(":visible")) {
                $dropdownMenu.slideUp();
                $(this).removeClass('open');
            } else {
                $dropdownMenu.slideDown();
                $(this).addClass('open');
                $('body').addClass('openMenu');
            }
        })
    });

    actionsScroll();
    $(window).on('scroll', function () {
        actionsScroll();
    });

    // INIT ALL FUNCTIONS
    imgBack();
    sliders();
    if ($('.block-accordeon').length != 0) { accordeon(); }
    if ($('.tabs').length != 0) { onglets(); }
    animationScroll();
});

function actionsScroll() {
    var scrolled = $(window).scrollTop();

    if (scrolled > 0) {
        $('body').addClass('scrolling').removeClass('notscroll');

    } else {
        $('body').addClass('notscroll').removeClass('scrolling');
    }
}

function imgBack() {
    $('.backImg').each(function () {
        var urlImgCurrent = $(this).children('img').attr('src');
        if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
            $(this).attr('style', 'background-image:url(' + urlImgCurrent + '); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
            $(this).find('img').remove();
        }
    });
}

function sliders() {
    $('.bloc--carrousel').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev material-symbols-sharp">navigate_before</span>',
        nextArrow: '<span class="slide-next material-symbols-sharp">navigate_next</span>',
    });
}

function accordeon() {
    jQuery(".list-item__title").on("click", function () {
        jQuery(this).toggleClass("active");
        jQuery(this).next(".list-item__content").slideToggle().toggleClass('active');
    });
}

function animationScroll() {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    if ($('.title--anim').length != 0) {
        let scrollAnimTitle = gsap.utils.toArray('.title--anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if ($('.elem--anim-top').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if ($('.elem--anim-enter').length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }

    const initMap = ($el) => {
        // Find marker elements within map.
        var $markers = $el.find('.marker');

        // Create gerenic map.
        var mapArgs = {
            zoom: $el.data('zoom') || 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map($el[0], mapArgs);

        // Add markers.
        map.markers = [];
        $markers.each(function () {
            initMarker($(this), map);
        });

        // Center map based on markers.
        centerMap(map);

        // Return map instance.
        return map;
    }

    const initMarker = ($marker, map) => {
        // Get position from marker.
        var lat = $marker.data('lat');
        var lng = $marker.data('lng');
        var latLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };

        // Create marker instance.
        var marker = new google.maps.Marker({
            position: latLng,
            map: map
        });

        // Append to reference for later use.
        map.markers.push(marker);

        // If marker contains HTML, add it to an infoWindow.
        if ($marker.html()) {

            // Create info window.
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
            });
        }
    }

    const centerMap = (map) => {
        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function (marker) {
            bounds.extend({
                lat: marker.position.lat(),
                lng: marker.position.lng()
            });
        });

        // Case: Single marker.
        if (map.markers.length == 1) {
            map.setCenter(bounds.getCenter());

            // Case: Multiple markers.
        } else {
            map.fitBounds(bounds);
        }
    }

    $('.acf-map').each(function () {
        var map = initMap($(this));
        console.log(map);
    });

    $('.slider-posts-block-container').slick({
        dots: true,
        prevArrow: '.container-controllers-prev',
        nextArrow: '.container-controllers-next',
        appendDots: '.container-controllers-dots',
        autoplay: true,
        autoplaySpeed: 2000,
    });

    $('.block-shortcut__items').slick({
        slidesToShow: 4,
        infinite: false,
        prevArrow: '.block-short__prev',
        nextArrow: '.block-short__next',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    })

    $('.card-post__infos-coord .toggle-button').on('mouseenter', function () {
        const id = $(this).data('id')

        $('#card-coord-' + id).addClass('active')
    })
    $('.coordinates-infos').on('mouseleave', function () {

        $('.coordinates-infos').removeClass('active')
    })

    var base_size = 0
    $('.text_increase').on('click', function() {
        if (base_size + 1 < 3) {
            base_size++
        }
    })
    $('.text_decrease').on('click', function() {
        if (base_size - 1 > -3) {
            base_size--
        }
    })

    $('.text_decrease, .text_increase').on("click", function() {
        if (base_size < 0) {
            $('html').removeClass()
            $('html').addClass('decrease-' + (base_size * -1))
        } else if (base_size > 0) {
            $('html').removeClass()
            $('html').addClass('increase-' + base_size)
        } else {
            $('html').removeClass()
        }
    })

    $('.item-search').on('click', function() {
        $('#site-navigation-search-mobile').slideToggle()
    })
    $('#site-navigation-burger').on('click', function() {
        $('#site-navigation-primary-mobile').slideToggle()
    })

    const create_div_el = (title, index) => {
        const html = `<div data-id="tab-element-block-${index}" class="tab-el-click">${title}</div>`
        return html
    }

    const initialize_tabs = () => {
        const tabs = jQuery('.tab-el').not(jQuery('.start-tab'))
        const tab_blocks = jQuery('#tabs-block')

        tabs.each((index, el) => {
            const title = jQuery(el).data('title')

            jQuery(el).attr('id', `tab-element-block-${index + 1}`)

            tab_blocks.append(create_div_el(title, index+1))
        })
    }

    const change_tabs = () => {
        jQuery('.tab-el-click').on('click', function() {
            const id = jQuery(this).data('id')
            jQuery('.tab-el, .tab-el-click').removeClass('active-tab')
            jQuery(this).addClass('active-tab')
            if (!id) {
                jQuery("#block-start-tab").addClass('active-tab')
            } else {
                jQuery("#" + id).addClass('active-tab')
            }
        })
    }

    const block_tab = () => {
        initialize_tabs()
        change_tabs()
    }

    if (jQuery('#block-start-tab').length != 0) {
        block_tab();
    }
}